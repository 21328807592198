import { daEventCenter } from 'public/src/services/eventCenter/index'


export const exposePolicyPopup = () => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'expose_policy_authorize_popup',
    },
  })
}

export const clickPolicyPopup = (type) => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'click_policy_authorize_popup',
      type: type,
    },
  })
}

export const exposeFilterZeroPopup = () => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'expose_popup_zero_filter',
    },
  })
}

export const exposeRegistrationSuccessPop = (style_type) => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'expose_registration_success_pop',
      style_type
    },
  })
}

export const clickRegistrationSuccessPop = (btn_type, style_type) => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'click_registration_success_pop',
      btn_type,
      style_type
    },
  })
}

export const clickBenefitSection = (selected_index, style_type) => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'click_benefit_section',
      selected_index,
      style_type
    },
  })
}

export const clickNewUserCoupon = () => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'click_new_user_coupon'
    },
  })
}

export const exposeCannotAcceptCode = () => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'expose_cannot_accept_code'
    },
  })
}

export const clickCannotAcceptCode = () => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'click_cannot_accept_code'
    },
  })
}

export const exposeCannotAcceptCodePop = () => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'expose_cannot_accept_code_pop'
    },
  })
}

export const clickCannotAcceptCodePop = (data) => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'click_cannot_accept_code_pop',
      beacon: true,
      ...data
    },
  })
}

export const clickOtherSolutioins = () => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'click_other_solutioins',
      beacon: true
    },
  })
}

export const exposeLoginSmsPopup = () => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'expose_login_sms_popup'
    },
  })
}

export const clickLoginSmsPopup = (type) => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'click_login_sms_popup',
      type,
      beacon: true
    },
  })
}
